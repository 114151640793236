import React, {useCallback, useState} from 'react'
import './PortraitBlock.scss'
import {useTranslation} from 'react-i18next'
import Button from '../../UI/Button/Button'
import Modal from '../../components/Modal/Modal'
import DigitalOrderFormView from '../../components/DigitalOrderForm/DigitalOrderFormView'

function PortraitBlock() {
  const { t } = useTranslation()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const showModal = useCallback(() => setIsOpenModal(prev => !prev), [])

  return <><div className='portraitBlock'>
    <div className='innerWidth paddingPage'>
      <div className="portraitOrder">
        <div className="info">
          <h2>{t('Portrait creating', {ns: 'main'})}</h2>
          <p className="desc">
            {t('Portrait creating desc', {ns: 'main'})}<br/>
            {t('Portrait creating alert', {ns: 'main'})}
          </p>
          <Button onClick={showModal} className="mt-25">{t('orderBtn', { ns: 'UI' })}</Button>
        </div>
        <div className="images">
          <div className="they">
            <img src="/assets/images/she.webp" alt="Her portrait for illustrate"/>
            <img src="/assets/images/he.webp" alt="Him portrait for illustrate"/>
          </div>
          <img src="/assets/images/couple.webp" alt="Illustrate for couple in style of loveinbook"/>
        </div>
      </div>
    </div>
  </div>
  <Modal active={isOpenModal} setActive={setIsOpenModal}>
    <DigitalOrderFormView/>
  </Modal>
  </>
}

export default PortraitBlock

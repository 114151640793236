import React from 'react'
import LayoutDefault from '../modules/LayoutDefault'
import Loading from '../UI/Loading/Loading'
import OrderStatusView from '../components/OrderStatusView/OrderStatusView'

const Thanks = () => {
  const params = new URLSearchParams(window.location.search)
  const orderId = params.get('order')
  const digitalOrderId = params.get('digital_order')

  return (
    <LayoutDefault>
      <div className='pageBlock'>
        <div className='innerWidth paddingPage'>
          <div className="contentBlock">
            <div className="flexColumn flexGrow text-center">
              {(orderId || digitalOrderId) ? <OrderStatusView orderId={orderId || digitalOrderId} digital={!!digitalOrderId}/> : <Loading/>}
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  )
}

export default Thanks
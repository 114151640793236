import React, {useCallback, useState} from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import defaultConfig from "../../default-config.json"
import './DigitalOrderFormView.scss'
import {saveDigitalOrder} from '../../API/orderAPI'
import {useNavigate} from 'react-router-dom'

function DigitalOrderFormView() {
  const { t } = useTranslation()
  const prices = defaultConfig.prices['ua']
  const navigate = useNavigate()
  const [isValid, setIsValid] = useState(false)
  const [isProcess, setIsProcess] = useState(false)
  const [orderSettings, setOrderSettings] = useState({
    portrait: 1,
    phone: '+380',
    comment: '',
    total: null
  })

  const saveOrderSettings = useCallback((val, key) => {
    setOrderSettings(prev => {
      const newSettings = {...prev, [key]: val}
      setIsValid(newSettings.phone && newSettings.phone !== '+380')
      return newSettings
    })
  }, [])

  const makeOrder = useCallback(async () => {
    if (!isValid)
      return false

    const total = {
      price: prices.portrait * orderSettings.portrait,
      currency: prices.currency
    }
    setIsProcess(true)
    await saveDigitalOrder({...orderSettings, total})
        .then(async (order) => {
          order._id && navigate('/check?digital_order=' + order._id)
        })
        .catch(e => console.error(e))
        .finally(() => setIsProcess(false))
  }, [isValid, orderSettings])

  return (
      <div className='digitalOrderFormView'>
        <h3>{t('Creating portrait', {ns: 'order'})}</h3>
        <div className="groupBlock mt-25">
          <div className="info">
            {t('Fill form', {ns: 'order'})}
          </div>
          <div className="settingsBlock">
            <div className="d-flex">
              <label htmlFor="hard" className='radioBox'>
                <input id='hard' type='radio' name='portrait'
                       defaultChecked={orderSettings.portrait === 1}
                       onChange={e => saveOrderSettings(1, 'portrait')}/>
                <div className='box'></div>
                <span className='px-15'>{t('1 portrait', {ns: 'order'})} ({prices.portrait} {prices.currency})</span>
              </label>
              <div className='mt-5 mb-15 ml-35'/>
              <label htmlFor="soft" className='radioBox'>
                <input id='soft' type='radio' name='portrait'
                       defaultChecked={orderSettings.portrait === 2}
                       onChange={e => saveOrderSettings(2, 'portrait')}/>
                <div className='box'></div>
                <span className='px-15'>{t('2 portraits', {ns: 'order'})} ({prices.portrait * 2} {prices.currency})</span>
              </label>
            </div>
          </div>
          <div className="info mt-25 mb-10">
            {t('Telephone', {ns: 'order'})}
          </div>
          <Input value={orderSettings.phone} placeholder={t('Phone', {ns: 'order'})}
                 className={`${(orderSettings.phone === '') ? '' : 'filled'}`} mask //+380 (__) ___-__-__
                 onChange={e => saveOrderSettings(e.target.value, 'phone')}/>
          <div className="info mt-25 mb-15">
            {t('Leave your clarification for the artist', {ns: 'order'})}
          </div>
          <textarea placeholder={t('Comment', {ns: 'order'})}
                    value={orderSettings.comment || ''}
                    onChange={e => saveOrderSettings(e.target.value, 'comment')}/>
        </div>
        <Button onClick={makeOrder} disabled={!isValid || isProcess}>{t('orderBtn', { ns: 'UI' })}</Button>
      </div>
  )
}

export default DigitalOrderFormView

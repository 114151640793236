export const MAIN_ROUTE = '/'
export const PROFILE_ROUTE = '/profile'
export const ORDER_ROUTE = '/order'
export const THANKS_ROUTE = '/check'
export const FAQ_ROUTE = '/faq'
export const DELIVERY_ROUTE = '/delivery'
export const CONTACTS_ROUTE = '/contacts'
export const POLICY_ROUTE = '/privacy-policy'
export const WARRANTY_ROUTE = '/warranty'

export const availableFaceParts = ['zest','decorations','eye','eyebrows','lips','hair','beard','nose','glasses']
export const sequenceFaceParts = ['eye','eyebrows','nose','lips','hair','beard','zest','decorations','glasses']
export const sceneBlank = {
  "name": "Scene_000",
  "color": "color",
  "topic": [],
  "type": "empty",
  "settings": {},
  "mainText": {
      "couple_man": {"ua": "", "en": "", "pl": ""},
      "couple_woman": {"ua": "", "en": "", "pl": ""},
      "couple_him": {"ua": "", "en": "", "pl": ""},
      "couple_her": {"ua": "", "en": "", "pl": ""},
      "couple": {"ua": "", "en": "", "pl": ""},
      "only_man": {"ua": "", "en": "", "pl": ""},
      "only_woman": {"ua": "", "en": "", "pl": ""}
  },
  "otherText": []
}
export const drawAboveSceneList = ['scene_030']

/*ADMIN PART*/
export const ADMIN_ROUTE = '/admin'
export const SCENES_ROUTE = '/admin/scenes'
export const ADD_SCENES_ROUTE = '/admin/scenes/add'
export const EDIT_SCENES_ROUTE = '/admin/scenes/edit'
export const STATS_ROUTE = '/admin/stats'
export const DIGITAL_ORDERS_ROUTE = '/admin/orders/digital'
export const ORDERS_ROUTE = '/admin/orders'
export const VOUCHERS_ROUTE = '/admin/vouchers'
export const ADD_VOUCHERS_ROUTE = '/admin/vouchers/add'
export const EDIT_VOUCHERS_ROUTE = '/admin/vouchers/edit'
export const COVERS_ROUTE = '/admin/covers'
import React, { useEffect, useState } from 'react'
import { getOrder } from '../../API/orderAPI'
import Loading from '../../UI/Loading/Loading'
import PaymentStatusView from './PaymentStatusView'

const OrderStatusView = ({orderId, digital}) => {
  const [order, setOrder] = useState()

  useEffect(() => {
    getOrder(orderId, digital).then(async order => {
      setOrder(order)
    }).catch(err => console.error(err))
  }, [orderId])

  return order ? <PaymentStatusView order={order} updateOrder={setOrder} digital={digital}/> : <Loading/>
}

export default OrderStatusView
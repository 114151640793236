// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/styles/fonts/NyghtSerif-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Nyght Serif";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("ttf");
  font-weight: normal;
  font-style: normal;
}
.digitalOrderFormView {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: 0 20px;
}
.digitalOrderFormView h3 {
  width: 100%;
}
.digitalOrderFormView .groupBlock {
  width: 100%;
  padding: 25px;
  border-radius: 20px;
  border: 1px solid #DDCABF;
  margin-bottom: 25px;
}
.digitalOrderFormView .groupBlock .info {
  color: #777777;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .digitalOrderFormView .groupBlock .info {
    margin-bottom: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/constants.scss","webpack://./src/components/DigitalOrderForm/DigitalOrderFormView.scss"],"names":[],"mappings":"AAOA;EACI,0BAAA;EACA,2KAAA;EAGA,mBAAA;EACA,kBAAA;ACRJ;AAHA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;AAKF;AAHE;EACE,WAAA;AAKJ;AAFE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAIJ;AAFI;EACE,cDKC;ECJD,gBAAA;EACA,eAAA;EACA,mBAAA;AAIN;AADI;EACE;IACE,mBAAA;EAGN;AACF","sourcesContent":["$xs :480px;\n$sm :640px;\n$md : 768px;\n$lg : 1024px;\n$xl: 1280px;\n$xxl: 1536px;\n\n@font-face {\n    font-family: \"Nyght Serif\";\n    src: url('./fonts/NyghtSerif-Light.woff2') format('woff2'),\n         url('./fonts/NyghtSerif-Light.woff') format('woff'),\n         url('./fonts/NyghtSerif-Light.ttf') format('ttf');\n    font-weight: normal;\n    font-style: normal;\n}\n\n$fontFamilyPrimary: 'Nyght Serif', sans-serif;\n$fontFamilySecondary: 'Manrope';\n$primary: #EF2768;\n$primary-hover: #E22160;\n$primary-disabled: #EB83A5;\n$secondary: #DDCABF;\n\n$white: #fff;\n$black: #303030;\n$gray: #777777;\n$error: red;\n$success: forestgreen;\n\n$bg-color-primary: #FFDDF1;\n$bg-color-secondary: #F9E4D7;\n$bg-color-light: #FFFBF4;\n$bg-ellipse-1: #E3C7FF;\n$bg-ellipse-2: #C7DDFF;\n$bg-ellipse-3: #FFD4C7;\n$bg-ellipse-4: #FEC9D3;","@import '../../assets/styles/constants.scss';\n\n.digitalOrderFormView {\n  display: flex;\n  flex-direction: column;\n  align-items: end;\n  margin: 0 20px;\n\n  h3 {\n    width: 100%;\n  }\n\n  .groupBlock {\n    width: 100%;\n    padding: 25px;\n    border-radius: 20px;\n    border: 1px solid $secondary;\n    margin-bottom: 25px;\n\n    .info {\n      color: $gray;\n      font-weight: 300;\n      font-size: 1rem;\n      margin-bottom: 25px;\n    }\n\n    @media (max-width: $md) {\n      .info {\n        margin-bottom: 15px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

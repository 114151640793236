import LayoutDefault from '../../modules/LayoutDefault';
import ReasonBlock from '../../modules/ReasonBlock/ReasonBlock';
import PromoBlock from '../../modules/PromoBlock/PromoBlock';
import StatisticBlock from '../../modules/StatisticBlock/StatisticBlock';
import InsideBlock from '../../modules/InsideBlock/InsideBlock';
import CreateBlock from '../../modules/CreateBlock/CreateBlock';
import ReviewBlock from '../../modules/ReviewBlock/ReviewBlock';
import PortraitBlock from '../../modules/PortraitBlock/PortraitBlock'

const Index = () => {
  return (
    <LayoutDefault>
      <PromoBlock/>
      <StatisticBlock/>
      <InsideBlock/>
      <CreateBlock/>
      <PortraitBlock/>
      <ReviewBlock/>
      <ReasonBlock/>
    </LayoutDefault>
  )
}

export default Index